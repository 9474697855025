export const Language = {
  en: {
    titlex: "Courses",
    button_next: "Next step",
  },
  de: {
    titlex: "Kurse",
    button_next: "Zum nächsten Schritt",
  },
  titlex: "Courses",
  du: {
    titlex: "Cursussen",
  },
  hu: {
    titlex: "Tanfolyamok",
  },
  fr: {
    titlex: "Cours",
  },
  es: {
    titlex: "Cursos",
  },
  pt: {
    titlex: "Cursos",
  },
  it: {
    titlex: "Corsi",
  },
  po: {
    titlex: "Kursy",
  },
  ru: {
    titlex: "Курсы",
    button_next: "Следующий шаг",
  },
  bg: {
    titlex: "Курсове",
  },
  gr: {
    titlex: "Μαθήματα",
  },
  tr: {},
  cz: {
    titlex: "Kurzy",
  },
  ro: {
    titlex: "Cursuri",
  },
  rshr: {},
};
