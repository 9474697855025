export default {
  submit: {
    en: "Submit test",
    de: "Test einreichen",
    ru: "Отправить тест",
    nl: "Test indienen", // Dutch
    fr: "Soumettre le test", // French
    es: "Enviar prueba", // Spanish
    pt: "Enviar teste", // Portuguese
    it: "Invia test", // Italian
    pl: "Złóż test", // Polish
    bg: "Изпрати теста", // Bulgarian
    el: "Υποβολή τεστ", // Greek
    hu: "Teszt beküldése", // Hungarian
    tr: "Testi gönder", // Turkish
    cs: "Odeslat test", // Czech
    ro: "Trimite testul", // Romanian
    sr: "Pošalji test", // Serbian
  },
  repeat: {
    en: "Repeat",
    de: "Wiederholen",
    ru: "Повторить",
    nl: "Herhalen", // Dutch
    fr: "Répéter", // French
    es: "Repetir", // Spanish
    pt: "Repetir", // Portuguese
    it: "Ripeti", // Italian
    pl: "Powtórzyć", // Polish
    bg: "Повторете", // Bulgarian
    el: "Επαναλάβετε", // Greek
    hu: "Ismétlés", // Hungarian
    tr: "Tekrar", // Turkish
    cs: "Opakovat", // Czech
    ro: "Repetați", // Romanian
    sr: "Ponoviti", // Serbian
  },
  button_next: {
    en: "Next step",
    de: "Zum nächsten Schritt",
    ru: "К следующему шагу",
    nl: "Volgende stap", // Dutch
    fr: "Étape suivante", // French
    es: "Siguiente paso", // Spanish
    pt: "Próximo passo", // Portuguese
    it: "Passo successivo", // Italian
    pl: "Następny krok", // Polish
    bg: "Следваща стъпка", // Bulgarian
    el: "Επόμενο βήμα", // Greek
    hu: "Következő lépés", // Hungarian
    tr: "Sonraki adım", // Turkish
    cs: "Další krok", // Czech
    ro: "Următorul pas", // Romanian
    sr: "Sledeći korak", // Serbian
  },
  result_text: {
    de: "Sie können jetzt Ihre Fehler einsehen. Da es mehrere Fehler gibt,sollte der Test ggf. die Schulung wiederholt werden",
    en: "You can now review your mistakes. Since there are several mistakes, the test should be repeated if necessary.", // English
    ru: "Теперь вы можете просмотреть свои ошибки. Поскольку ошибок несколько, при необходимости тест следует повторить.", // Russian
    nl: "U kunt nu uw fouten bekijken. Aangezien er meerdere fouten zijn, moet de test indien nodig worden herhaald.", // Dutch
    fr: "Vous pouvez maintenant revoir vos erreurs. Comme il y a plusieurs erreurs, le test doit être répété si nécessaire.", // French
    es: "Ahora puedes revisar tus errores. Dado que hay varios errores, la prueba debe repetirse si es necesario.", // Spanish
    pt: "Você pode revisar seus erros agora. Como há vários erros, o teste deve ser repetido, se necessário.", // Portuguese
    it: "Ora puoi rivedere i tuoi errori. Poiché ci sono diversi errori, se necessario, il test deve essere ripetuto.", // Italian
    pl: "Możesz teraz przejrzeć swoje błędy. Ponieważ jest kilka błędów, test powinien zostać powtórzony, jeśli to konieczne.", // Polish
    bg: "Сега можете да прегледате грешките си. Тъй като има няколко грешки, ако е необходимо, тестът трябва да се повтори.", // Bulgarian
    el: "Μπορείτε τώρα να ελέγξετε τα λάθη σας. Δεδομένου ότι υπάρχουν αρκετά λάθη, η δοκιμή πρέπει να επαναληφθεί εάν είναι απαραίτητο.", // Greek
    hu: "Most megtekintheti hibáit. Mivel több hiba van, szükség esetén meg kell ismételni a tesztet.", // Hungarian
    tr: "Hatalarınızı şimdi gözden geçirebilirsiniz. Birkaç hata olduğu için, gerekirse testi tekrar edin.", // Turkish
    cs: "Nyní můžete zkontrolovat své chyby. Protože je zde několik chyb, měl by být test v případě potřeby zopakován.", // Czech
    ro: "Acum puteți revizui greșelile dvs. Deoarece există mai multe greșeli, testul ar trebui repetat, dacă este necesar.", // Romanian
    sr: "Sada možete pregledati svoje greške. Pošto ima više grešaka, test treba ponoviti ako je potrebno.", // Serbian
  },
};
