import React from "react";
import ImprintFooter from "../../utils/sharedComponents/ImprintFooter";
import { Link } from "react-router-dom";
import PrivacyDE from "./PrivacyDE";

const PrivacyStatement = () => {
  return (
    <div className="bg-mine">
      <Link to="/">
        <div className="d-flex justify-content-end">
          <button className="btn-smol">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </Link>
      <>
        <PrivacyDE />
      </>
    </div>
  );
};

export default PrivacyStatement;
