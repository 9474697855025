import React from "react";

const PrivacyDE = () => {
  return (
    <>
      <h3>I. Kontaktdaten des Verantwortlichen</h3>
      <br />
      Der Verantwortliche im Sinne der <br />
      Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze der
      Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist:
      <br /> <br />
      CDM Smith Consult GmbH <br />
      Am Umweltpark 3-5 <br />
      D-44793 Bochum <br />
      tel: 0234 68775-0 <br />
      fax: 0234 68775-10 <br />
      <a href="mailto:info@cdmsmith.com">info@cdmsmith.com</a>
      <br />
      Website:
      <a target="_blank" href="https://www.cdmsmith.com/de ">
        www.cdmsmith.com/de
      </a>
      <br /> <br />
      <h3>II. Kontaktdaten des Datenschutzbeauftragten</h3>
      <br />
      Der Datenschutzbeauftragte des <br /> <br />
      Verantwortlichen ist: <br />
      Scheja und Partner Rechtsanwälte mbB <br />
      Adenauerallee <br />
      136 <br />
      53113 Bonn <br />
      Deutschland <br />
      E-Mail: info@scheja-partner.de <br />
      <br />
      <h3>III. Beschreibung der Verarbeitung</h3> <br />
      <h5>1. Zweck der Verarbeitung</h5>
      Die Verarbeitung dient der Organisation, Vorbereitung (inkl. Einladung),
      Durchführung und dem Nachweis von Einweisungen.
      <h5>2. Rechtsgrundlage der Verarbeitung</h5>
      Rechtsgrundlagen für die Verarbeitung von Ihren personenbezogenen Daten
      sind: <br />
      <ul>
        <h6>
          a) Aufgrund einer Einwilligung (gem. Art. 6 Abs. 1 lit. a DSGVO)
        </h6>
        Die Zwecke der Verarbeitung personenbezogener Daten ergeben sich aus der
        Erteilung einer Einwilligung. Eine erteilte Einwilligung kann jederzeit
        von Ihnen mit Wirkung für die Zukunft widerrufen werden. Auch
        Einwilligungen, die vor der Geltung der DSGVO (25. Mai 2018) erteilt
        worden sind, können widerrufen werden. Verarbeitungen, die vor dem
        Widerruf erfolgt sind, bleiben vom Widerruf unberührt.
        <br />
        <br />
        <h6>
          b) Zur Vertragsanbahnung, Vertragsdurchführung und Beendigung von
          Vertragsverhältnissen (gem. Art. 6 Abs. 1 lit. b DSGVO)
        </h6>
        Die Zwecke der Datenverarbeitung ergeben sich zum einen aus der
        Einleitung vorvertraglicher Maßnahmen, die einer vertraglich geregelten
        Geschäftsbeziehung vorausgehen und zum anderen zur Erfüllung der
        Pflichten aus dem mit Ihnen geschlossenen Vertrag.
        <br />
        <br />
        <h6>
          c) Aufgrund gesetzlicher Vorgaben (gem. Art. 6 Abs.1 lit. c DSGVO)
          oder im öffentlichen Interesse (gem. Art. 6 Abs.1 lit. e DSGVO)
        </h6>
        Die Zwecke der Datenverarbeitung ergeben sich aus gesetzlichen Vorgaben
        oder liegen im öffentlichen Interesse (z. B. Einhaltung von
        Aufbewahrungspflichten, Nachweis der Einhaltung von Hinweis- und
        Informationspflichten des Steuerberaters, etc.).
        <br />
        <h6>
          d) Aufgrund eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO)
        </h6>
        Die Zwecke der Verarbeitung ergeben sich aus der Wahrung unserer
        berechtigten Interessen. Es kann erforderlich sein, die von Ihnen
        überlassenen Daten über die eigentliche Erfüllung des Vertrages hinaus
        zu verarbeiten. Unser berechtigtes Interesse kann zur Begründung der
        weiteren Verarbeitung der von Ihnen überlassenen Daten herangezogen
        werden, sofern Ihre Interessen oder Grundrechte und Grundfreiheiten
        nicht überwiegen. Unser berechtigtes Interesse kann im Einzelfall sein:
        Geltendmachung rechtlicher Ansprüche, Abwehr von Haftungsansprüchen,
        Verhinderung von Straftaten, Gewährleistung von Sicherheit und
        Integrität unserer Systeme.
      </ul>
      <h5>3. Umfang der Datenverarbeitung </h5>
      Für die vorgenannten Zwecke verarbeitet die CDM Smith Consult GmbH
      gegebenenfalls die folgenden Kategorien personenbezogener Daten:
      <br />
      <ul>
        a. Angaben zum Teilnehmer Stammdaten (Vor- und Nachname, Geburtstag);
        zugehörige Firma, E-Mail-Adresse
        <br />
        b. Schulungs- und Teilnahmedaten
        <br />
        c. Logfiles, Protokolldaten, Metadaten (z.B. IP-Adresse, Zeitpunkt des
        Zugriffs, etc.)
      </ul>
      Ihre personenbezogenen Daten beziehen wir direkt von Ihnen bzw. vom
      Unternehmen, welches Sie für die Teilnahme an den Schulungen angemeldet
      hat. Zur Verarbeitung der von Ihnen überlassenen Daten kommt keine
      vollautomatisierte Entscheidungsfindung (einschließlich Profiling) gem.
      Art. 22 DS-GVO zum Einsatz.
      <h5>4. Datenlöschung und Speicherdauer </h5>
      Die Verarbeitung der von Ihnen überlassenen Daten erfolgt so lange, wie
      sie zur Erreichung des vertraglich vereinbarten Zweckes notwendig ist,
      grundsätzlich solange das Vertragsverhältnis mit Ihnen besteht. Nach der
      Beendigung des Vertragsverhältnisses werden die von Ihnen überlassenen
      Daten zur Einhaltung gesetzlicher Aufbewahrungspflichten oder aufgrund
      unserer berechtigten Interessen verarbeitet. Nach dem Ablauf der
      gesetzlichen Aufbewahrungsfristen und/oder dem Wegfall unserer
      berechtigten Interessen werden die von Ihnen überlassenen Daten gelöscht.
      <h5>5. Empfänger der Daten </h5>
      Es haben nur Mitarbeiter Zugriff auf Ihre Daten, die für das Thema
      Einweisungen-Online zuständig sind, sowie die Geschäftsführung und der
      Datenschutzbeauftragte.
      <br />
      Im Rahmen unserer Leistungserbringung beauftragen wir Auftragsverarbeiter,
      die zur Erfüllung der vertraglichen Pflichten beitragen. Die CDM Smith
      Consult GmbH arbeitet mit Dienstleistern, wie beispielsweise
      Dienstleistern für IT-Wartungsleistungen, zusammen (sog.
      Auftragsverarbeiter). Diese Dienstleister werden sind vertraglich auf die
      Einhaltung der geltenden datenschutzrechtlichen Anforderungen
      verpflichtet.
      <h5>6. Datenübermittlung an Drittländer</h5>
      Eine Übermittlung der von Ihnen überlassenen Daten an ein Drittland oder
      eine internationale Organisation erfolgt in keinem Fall. Sollten Sie im
      Einzelfall eine weitere Übermittlung der von Ihnen überlassenen Daten an
      ein Drittland oder eine internationale Organisation wünschen, führen wir
      dies nur nach Ihrer Einwilligung durch.
      <br />
      <br />
      <h3>IV. Auskunft über Ihre Rechte:</h3>
      <br />
      <h5>1. Auskunftsrecht </h5>
      Sie haben das Recht, jederzeit im Umfang von Art. 15 DSGVO Auskunft über
      Ihre personenbezogenen Daten zu verlangen, die wir verarbeiten.
      <br />
      <h5>2. Recht auf Berichtigung und Ergänzung:</h5>
      Sollten Ihre personenbezogenen Daten unrichtig oder unvollständig sein,
      haben Sie im Umfang von Art. 16 DSGVO ein Recht auf Berichtigung und
      Ergänzung.
      <br />
      <h5>3. Recht auf Einschränkung der Verarbeitung</h5>
      Bei Vorliegen der gesetzlichen Voraussetzungen können Sie im Umfang von
      Art. 18 DSGVO eine Einschränkung der Verarbeitung Ihrer personenbezogenen
      Daten verlangen.
      <br />
      <h5>4. Recht auf Löschung</h5>
      Sie können im Umfang von Art. 17 DSGVO jederzeit die Löschung Ihrer
      personenbezogenen Daten verlangen, sofern wir nicht rechtlich zur weiteren
      Verarbeitung Ihrer Daten verpflichtet oder berechtigt sind.
      <br />
      <h5>5. Recht auf Datenübertragbarkeit</h5>
      Erfolgt eine Verarbeitung auf Grundlage Ihrer Einwilligung und mithilfe
      automatisierter Verfahren, so haben Sie im Umfang von Art. 20 DSGVO ein
      Recht auf Übertragung der von Ihnen bereitgestellten Daten, sofern dadurch
      nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.
      <br />
      <h5>6. Widerspruchsrecht</h5>
      Sie haben das Recht, im Umfang von Art. 21 DSGVO gegen eine Verarbeitung
      Widerspruch zu erheben, soweit die Datenverarbeitung zum Zwecke der
      Direktwerbung oder des Profilings erfolgt. Einer Verarbeitung auf Grund
      einer Interessenabwägung können Sie unter Angabe von Gründen, die sich aus
      Ihrer besonderen Situation ergeben, widersprechen.
      <br />
      <h5>
        7. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
      </h5>
      Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung
      jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die
      Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
      Verarbeitung nicht berührt.
      <br />
      <h5>8. Recht auf Beschwerde bei einer Aufsichtsbehörde</h5>
      Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
      Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer
      Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts,
      ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn
      Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
      personenbezogenen Daten gegen die DSGVO verstößt.
      <br />
      Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
      unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der
      Beschwerde einschließlich der Möglichkeit eines gerichtlichen
      Rechtsbehelfs nach Art. 78 DSGVO.
      <div style={{ height: 50 }}></div>
    </>
  );
};

export default PrivacyDE;
