export default {
  play: {
    en: "Play video",
    de: "Video abspielen",
    ru: "Воспроизвести видео",
  },
  pause: {
    en: "Pause",
    de: "Pause",
    ru: "Пауза",
  },
  repeat: {
    en: "Repeat",
    de: "Wiederholen",
    ru: "Повторите",
  },
  button_next: {
    en: "Next step",
    de: "Zum nächsten Schritt",
    ru: "К следующему шагу",
  },
};
