import React from "react";
import { useHistory } from "react-router-dom";

const Logout = () => {
  const history = useHistory();

  const onClick = () => {
    history.push({
      pathname: "/",
    });
  };
  return (
    <div>
      <button onClick={onClick} className="footer-logout">
        Logout
      </button>
    </div>
  );
};

export default Logout;
