import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Endpoints } from "../../repository/Endpoints";
import Logout from "../../utils/sharedComponents/Logout";
import { Spinner } from "react-bootstrap";
import {
  PostMaker,
  StreamMaker,
  SubtitleStreamMaker,
} from "../../repository/RequestMaker";
import Language from "./Language";
import useAuth from "../../utils/customHooks/useAuth";

const PLAYING_DEBOUNCE_TIME = 50;
const WAITING_DEBOUNCE_TIME = 200;

const Training = ({ vidUrl, materialChanger, material_id }) => {
  useAuth();
  //inits
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  // const [vidUrl, setVidUrl] = useState(localStorage.getItem("video_url"));
  const [subUrl, setSubUrl] = useState("");
  const vidRef = useRef(null);
  // const material_id = localStorage.getItem("material_id");
  const language = localStorage.getItem("language");

  const [isPlaying, setIsPlaying] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const isWaitingTimeout = useRef(null);
  const isPlayingTimeout = useRef(null);
  const videoElementRef = useRef();
  const [videoEnded, setVideoEnded] = useState(false);
  const [sendingData, setSendingData] = useState(false);

  //logics

  const toNext = (e) => {
    e.preventDefault();
    setSendingData(true);
    PostMaker(
      {
        section_material_id: material_id,
        course_enrollment_id: localStorage.getItem("enrollment_id"),
      },
      Endpoints.material_progress
    ).then((response) => {
      localStorage.setItem(
        "response" + Math.random(),
        JSON.stringify(response)
      );
      // const current_material = parseInt(
      //   localStorage.getItem("current_material")
      // );
      // localStorage.setItem("current_material", current_material + 1);
      setTimeout(() => {
        // history.push({
        //   pathname: "/material",
        // });
        setVideoEnded(false);
        setSendingData(false);
        setIsPlaying(false);
        materialChanger();
      }, 500);
    });
  };
  const playVideo = (e) => {
    vidRef.current.play();
  };
  const pauseVideo = (e) => {
    vidRef.current.pause();
  };

  useEffect(() => {
    //   window.scrollTo(0, 0);
    const material_id = localStorage.getItem("material_id");
    // setVidUrl(localStorage.getItem('video_url'));
    setIsLoading(false);
    if (localStorage.getItem("dev") === "true") {
      setVideoEnded(true);
    }
    // StreamMaker(
    //   Endpoints.education_section_materials_get_video_pseudo_stream +
    //     material_id
    // ).then((res) => {
    //   setVidUrl(res);
    //   setIsLoading(false);
    //   setTimeout(() => {
    //     videoElementRef.current.play();
    //   }, 100);
    // });
  }, [materialChanger]);

  useEffect(() => {
    if (!videoElementRef.current) {
      return;
    }

    const element = videoElementRef.current;

    element.addEventListener("waiting", waitingHandler);
    element.addEventListener("play", playHandler);
    element.addEventListener("playing", playHandler);
    element.addEventListener("pause", pauseHandler);

    // clean up
    return () => {
      clearTimeout(isWaitingTimeout.current);
      clearTimeout(isPlayingTimeout.current);

      element.removeEventListener("waiting", waitingHandler);
      element.removeEventListener("play", playHandler);
      element.removeEventListener("playing", playHandler);
      element.removeEventListener("pause", pauseHandler);
    };
  }, [videoElementRef]);

  const waitingHandler = () => {
    clearTimeout(isWaitingTimeout.current);

    isWaitingTimeout.current = setTimeout(() => {
      setIsWaiting(true);
    }, WAITING_DEBOUNCE_TIME);
  };

  const playHandler = () => {
    clearTimeout(isWaitingTimeout.current);
    clearTimeout(isPlayingTimeout.current);

    isPlayingTimeout.current = setTimeout(() => {
      setIsPlaying(true);
      setVideoEnded(false);
      setIsWaiting(false);
    }, PLAYING_DEBOUNCE_TIME);
  };

  const pauseHandler = () => {
    clearTimeout(isWaitingTimeout.current);
    clearTimeout(isPlayingTimeout.current);

    isPlayingTimeout.current = setTimeout(() => {
      setIsPlaying(false);
      setIsWaiting(false);
    }, PLAYING_DEBOUNCE_TIME);
  };

  const handlePlayPauseClick = () => {
    if (videoElementRef.current) {
      if (isPlaying) {
        videoElementRef.current.pause();
      } else {
        videoElementRef.current.play();
      }
    }
  };
  // useEffect(() => {
  //   if (!isPlaying && !videoEnded) {
  //     const mediaSymbol = document.getElementById("mediaSymbol");
  //     if (mediaSymbol.classList.contains("fa-repeat")) {
  //       window.location.reload();
  //     }
  //   }
  // }, [isPlaying, videoEnded]);
  return (
    <div>
      {isLoading ? (
        <div
          className="d-flex justify-content-center"
          style={{
            zIndex: 999,
            height: "100vh",
            width: "100vw",
            position: "absolute",
            alignItems: "center",
            alignContent: "center",
            top: 0,
            left: 0,
            marginTop: -60,
          }}
        >
          <Spinner animation="grow" />
        </div>
      ) : (
        <></>
      )}
      <div className="bg-mine">
        <div className="embed-responsive embed-responsive-16by9">
          <>
            <video
              id="myVideo"
              // crossorigin="anonymous"
              className="embed-responsive-item"
              ref={videoElementRef}
              // src={localStorage.getItem("video_url")}
              src={vidUrl}
              style={{ width: "100%", height: "auto", borderRadius: "20px" }}
              onEnded={() => {
                setVideoEnded(true);
              }}
              playsInline
            >
              <source src={vidUrl} type="video/mp4" />
              {/* <track
                  label="English"
                  kind="subtitles"
                  srcLang="en"
                  src={subUrl}
                  // src={localStorage.getItem("subtitle_url")}
                  default
                /> */}
              Your browser does not support the video tag.
            </video>

            {/* buttons */}
            <div className="margin_top"></div>
            <div class="d-flex bd-highlight mb-3">
              <div class="p-2 bd-highlight">
                <button
                  onClick={handlePlayPauseClick}
                  className="btn-round btn-media"
                >
                  {isPlaying ? (
                    <span>
                      {Language.pause[language]}
                      <i id="mediaSymbol" className="fa-solid fa-pause icon-border"></i>
                    </span>
                  ) : (
                    <>
                      {videoEnded ? (
                        <span>
                          {Language.repeat[language]}
                          <i id="mediaSymbol" class="fa-solid fa-repeat icon-border"></i>
                        </span>
                      ) : (
                        <span>
                          {Language.play[language]}
                          <i id="mediaSymbol" className="fa-solid fa-play icon-border"></i>
                        </span>
                      )}
                    </>
                  )}
                  {isWaiting && (
                    <span className="SimpleVideo-loader">Buffering</span>
                  )}
                </button>
              </div>
              <div class="ms-auto p-2 bd-highlight">
                {isLoading ? (
                  <></>
                ) : videoEnded ? (
                  sendingData ? (
                    <Spinner animation="grow" />
                  ) : (
                    <button
                      //className="btn btn-outline-dark"
                      className="btn-round"
                      onClick={toNext}
                    >
                      {Language.button_next[language]}
                      <i class="fa fa-forward icon-border"></i>
                    </button>
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default Training;
