import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";

const MaterialRouter = () => {
  //inits
  const material = JSON.parse(localStorage.getItem("material"));
  console.log("material:", material);
  const material_length = localStorage.getItem("material_length");
  const current_material = localStorage.getItem("current_material");
  const history = useHistory();
  //logics
  useEffect(() => {
    var m_l = parseInt(material_length);
    var c_m = parseInt(current_material);
    var difference = m_l - c_m;
    if (difference === 1) {
      history.push({
        pathname: "/certificate",
      });
    } else {
      const index = c_m + 1;
      console.log("check material", material.length, index);
      const type = material[index].material_type.const;
      localStorage.setItem("material_id", material[index].id);
      console.log(type);
      if (type === "VIDEO_WITH_SUBTITLE") {
        localStorage.setItem("video_url", material[index].public_video_url);
        localStorage.setItem("subtitle_url", material[index].subtitle_url);
        history.push({
          pathname: "/training",
        });
      } else if (type === "VIDEO_STANDALONE") {
        localStorage.setItem("video_url", material[index].public_video_url);
        localStorage.setItem("attempt", "0");
        history.push({
          pathname: "/training",
        });
      } else if (type === "PDF") {
        history.push({
          pathname: "/trainingpdf",
        });
      } else {
        history.push({
          pathname: "/quiz",
        });
      }
    }
  }, []);
  return <div></div>;
};

export default MaterialRouter;
