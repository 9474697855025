import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Endpoints } from "../../repository/Endpoints";
import { StreamMaker } from "../../repository/RequestMaker";

const TestingUI = () => {
  const history = useHistory();

  useEffect(() => {
    localStorage.setItem("lastRoute", "grouptraining");
    setTimeout(() => {
      history.push({
        pathname: "/",
      });
    }, 1000);
  }, []);

  return (
    <div className={"bg-mine"}>
      <Spinner animation="grow" />
    </div>
  );
};

export default TestingUI;
