export const Language = {
  en: {
    title_text: "Welcome to your",
    title_text_2: "Digital Instructions on",
    title_text_3: "Occupational Safety",
    privacy_agree_text: "I have read and agree to the",
    privacy_agree_link: "Privacy Notice & Terms of Use",
    privacy_agree_text_2: "",
    next_button_statement:
      'To continue, please activate the checkbox "I have read the Privacy Notice & Terms of Use ...".',
    imprint_button: "Imprint",
    language_text: "Language",
    button_next: "Next step",
  },
  de: {
    title_text: "Willkommen zu Ihrer",
    title_text_2: "Digitalen Einweisung",
    title_text_3: "Arbeitssicherheit",
    privacy_agree_text: "Ich habe die",
    privacy_agree_link: "Datenschutzhinweise",
    privacy_agree_text_2: "gelesen und bin damit einverstanden.",
    next_button_statement:
      'Bitte klicken Sie zum Fortfahren auf das Kontrollkästchen neben der Erklärung "Ich habe die Datenschutzhinweise & Nutzungsbedingungen gelesen ...".',
    imprint_button: "Impressum",
    language_text: "Sprache",
    button_next: "Zum nächsten Schritt",
  },
  du: {
    title_text: "Selecteer taal",
    subtitle_text: "Selecteer de gevraagde taal",
    //language_text: "Taal",
    privacy_agree_text:
      'Ik heb de <a href="./privacyStatement"> privacyverklaring en gebruiksvoorwaarden </a> gelzen en ik ga akkoord',
    next_button_statement:
      'Klik op het selectievakje naast de stelling "Ik heb uw privacyverklaring gelzen" om verder te gaan.',
    imprint_button: "afdruk",
  },
  hu: {
    title_text: "Nyelv kiválasztása",
    subtitle_text: "Kérjük, válassza ki a kívánt nyelvet",
    //language_text: "Nyelv",
    privacy_agree_text:
      'Elolvastam az <a href="./privacyStatement"> adatvédelmi nyilatkozatot és a felhasználási feltételeket </a>, és elfogadom',

    next_button_statement:
      'A folytatáshoz kattintson az "Elolvastam az adatvédelmi nyilatkozatát" nyilatkozat melletti jelölőnégyzetre.',
    imprint_button: "imprint",
  },
  fr: {
    title_text: "Sélectionnez la langue",
    subtitle_text: "Veuillez sélectionner la langue souhaitée",
    //language_text: "La langue",
    privacy_agree_text:
      "J'ai lu <a href='./privacyStatement'>la déclaration de confidentialité et les conditions d'utilisation</a> et je les accepte",

    next_button_statement:
      "Veuillez cliquer sur la case à cocher à côté de la déclaration J'ai lu votre déclaration de confidentialité pour continuer.",
    imprint_button: "mentions légales",
  },
  es: {
    title_text: "Seleccione el idioma",
    subtitle_text: "Por favor seleccione el idioma solicitado",
    //language_text: "Lenguaje",
    privacy_agree_text:
      "<a href='./privacyStatement'>He leído la declaración de privacidad y los términos de uso y estoy de acuerdo</a>",

    next_button_statement:
      "Por favor haga clic en la casilla de verificación junto a la declaración He leído su declaración de privacidad para proceder..",
    imprint_button: "Imprimir",
  },
  pt: {
    title_text: "Bem-vindo ao seu",
    title_text_2: "Instruções Digitais sobre",
    title_text_3: "Segurança Ocupacional",
    privacy_agree_text: "Li e concordo com a",
    privacy_agree_link: "Política de Privacidade e Termos de Uso",
    privacy_agree_text_2: "",
    next_button_statement:
      'Para continuar, por favor ative a caixa "Li a Política de Privacidade e Termos de Uso...".',
    imprint_button: "Impressão",
    language_text: "Idioma",
    button_next: "Próxima etapa",
  },
  
  it: {
    title_text: "Selezionare la lingua",
    subtitle_text: "Si prega di selezionare la lingua richiesta",
    //language_text: "Lingua",
    privacy_agree_text:
      "<a href='./privacyStatement'>Ho letto l'informativa sulla privacy e le condizioni d'uso e accetto</a>",

    next_button_statement:
      "Per favore cliccate sulla casella di controllo accanto alla dichiarazione Ho letto la vostra dichiarazione sulla privacy per procedere",
    imprint_button: "impronta",
  },
  po: {
    title_text: "Witaj w",
    title_text_2: "Cyfrowych Instrukcjach dotyczących",
    title_text_3: "Bezpieczeństwa Zawodowego",
    privacy_agree_text: "Przeczytałem(am) i zgadzam się z",
    privacy_agree_link: "Notą Prywatności i Warunkami Korzystania",
    privacy_agree_text_2: "",
    next_button_statement:
      'Aby kontynuować, proszę aktywować pole wyboru "Przeczytałem(am) Notę Prywatności i Warunki Korzystania...".',
    imprint_button: "Odcisk",
    language_text: "Język",
    button_next: "Następny krok",
  },
  ru: {
    title_text: "Добро пожаловать на Ваш",
    title_text_2: "Цифровой брифинг ",
    title_text_3: "По охране труда",
    privacy_agree_text: "Я прочитал(а) и согласен(а) с",
    privacy_agree_link: "Уведомлением о конфиденциальности и условиями использования",
    privacy_agree_text_2: "",
    next_button_statement:
      'Чтобы продолжить, пожалуйста, активируйте флажок "Я прочитал(а) Уведомление о конфиденциальности и условия использования...".',
    imprint_button: "Оттиск",
    language_text: "Язык",
    button_next: "Следующий шаг",
  },
  bg: {
    title_text: "Избери език",
    subtitle_text: "Моля изберете искания език",
    //language_text: "Език",
    privacy_agree_text:
      "<a href='./privacyStatement'>Прочетох декларацията за поверителност и условията за ползване и се съгласявам</a>",

    next_button_statement:
      "Моля щракнете върху квадратчето до изявлението че съм прочел декларацията ви за поверителност за да продължите.",
    imprint_button: "отпечатък",
  },
  gr: {
    title_text: "Επιλέξτε γλώσσα",
    subtitle_text: "Παρακαλώ επιλέξτε τη ζητούμενη γλώσσα",
    //language_text: "Γλώσσα",
    privacy_agree_text:
      "<a href='./privacyStatement'>Έχω διαβάσει τη δήλωση απορρήτου και τους όρους χρήσης και συμφωνώ</a>",

    next_button_statement:
      "Κάντε κλικ στο πλαίσιο ελέγχου δίπλα στη δήλωση που έχω διαβάσει τη δήλωση απορρήτου σας για να συνεχίσω.αποτύπωμα",
    imprint_button: "undefined",
  },
  hu: {
    title_text: "Select Language",
    subtitle_text: "Please select the requested language",
    //language_text: "Language",
    privacy_agree_text:
      'I have read the <a href="./privacyStatement">privacy statement & terms of use</a> and I agree',

    next_button_statement:
      'Please click on the checkbox next to the statement "I have read your privacy statement" to proceed.',
    imprint_button: "imprint",
  },
  po: {
    title_text: "Hoş Geldiniz",
    title_text_2: "Dijital Talimatlarınıza",
    title_text_3: "İş Güvenliği",
    privacy_agree_text: "Okudum ve kabul ediyorum",
    privacy_agree_link: "Gizlilik Bildirimi ve Kullanım Koşulları",
    privacy_agree_text_2: "",
    next_button_statement:
      "Devam etmek için lütfen 'Gizlilik Bildirimi ve Kullanım Koşulları'nı okudum... kutusunu işaretleyin.",
    imprint_button: "Damga",
    language_text: "Dil",
    button_next: "Sonraki adım",
  },
  
  cz: {
    title_text: "Zvolte jazyk",
    subtitle_text: "Vyberte požadovaný jazyk",
    //language_text: "Jazyk",
    privacy_agree_text:
      "<a href='./privacyStatement'>Přečetl jsem si prohlášení o ochraně osobních údajů a podmínky použití a souhlasím</a>",

    next_button_statement:
      "Pokračujte kliknutím na zaškrtávací políčko vedle prohlášení. Přečetl jsem si vaše prohlášení o ochraně osobních údajů.",
    imprint_button: "otisk",
  },
  ro: {
    title_text: "Selecteaza limba",
    subtitle_text: "Vă rugăm să selectați limba solicitată",
    //language_text: "Limba",
    privacy_agree_text:
      "<a href='./privacyStatement'>Am citit declarația de confidențialitate și termenii de utilizare și sunt de acord</a>",

    next_button_statement:
      "Faceți clic pe caseta de selectare de lângă declarația Am citit declarația dvs. de confidențialitate pentru a continua.",
    imprint_button: "imprima",
  },
  rshr: {
    title_text: "Изаберите језик",
    subtitle_text: "Изаберите тражени језик",
    //language_text: "Језик",
    privacy_agree_text:
      "<a href='./privacyStatement'>Прочитао сам изјаву о приватности и услове коришћења и слажем се</a>",

    next_button_statement:
      "Кликните на поље за потврду поред изјаве Прочитао сам вашу изјаву о приватности да бисте наставили.",
    imprint_button: "отисак",
  },
};
