import React from "react";
import { Link } from "react-router-dom";
const Imprint = () => {
  return (
    <div className="bg-mine">
      <Link to="/">
        <div className="d-flex justify-content-end">
          <button className="btn-smol">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </Link>

      <p>Impressum</p>
      <br />
      <p>CDM Smith Consult GmbH</p>
      <p>Am Umweltpark 3-5</p>
      <p>D-44793 Bochum</p>
      <p>tel: 0234 68775-0</p>
      <p>fax: 0234 68775-10</p>
      <p>
        <a href="mailto: info@cdmsmith.com">info@cdmsmith.com</a>
      </p>
      <br />
      <p>
        UstID: DE12 9441 841 · Sitz der Gesellschaft: Bochum · Amtsgericht
        Bochum HRB 10957
      </p>
      <p>Geschäftsführung: Dr. Ralf Bufler (Vorsitz) · Andreas Roth</p>
    </div>
  );
};

export default Imprint;
