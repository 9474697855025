import React, { useState, useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { Accordion, Card, Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { GetMaker, PostMaker } from "../../repository/RequestMaker";
import { Endpoints } from "../../repository/Endpoints";
import { Translations } from "../../repository/Translations";
import Logout from "../../utils/sharedComponents/Logout";
import { Language } from "./Language";
import useAuth from "../../utils/customHooks/useAuth";

const Courses = () => {
  useAuth();
  // inits
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const language = localStorage.getItem("language");
  //const selectedCourseIDs = []; // array to push the course IDs
//  const [selectedCourseIDs, setSelectedCourseIDs] = useState([]); // array to push the course IDs
  const [selectedCount, setSelectedCount] = useState(0); // count for checkboxes to check how many checkboxes are selected.
  const [selectedCourseId, setSelectedCourseId] = useState(null); // state to save the course ID and pass it to the next button
  const [clickedCheckboxes, setClickedCheckboxes] = useState([]);


  // logics

  useEffect(() => {
    localStorage.setItem("lastRoute", "courses");
    window.scrollTo(0, 0);
    GetMaker(
      Endpoints.list_courses +
        localStorage.getItem("tenant_id") +
        "/" +
        localStorage.getItem("language_id")
    ).then((res) => {
      if (res) {
        setCourses(res.data);
        setIsLoading(false);
      }
    });
  }, []);

  useLayoutEffect(() => {
    // Translations("", Language);
  }, []);
  const onNext = (course_id, key, selectedCount) => {
    console.log("key is ", key);
    console.log("selectedCount is ", selectedCount);

    localStorage.setItem("checkboxCount", selectedCount);

    localStorage.setItem("course_id", course_id);
    document.getElementById("confirm" + key).innerHTML = "Loading...";
    PostMaker(
      {
        user_id: localStorage.getItem("user_id"),
        course_id: course_id,
        profile_id: localStorage.getItem("profile_id"),
        course_section : clickedCheckboxes,
      },
      Endpoints.enrollment
    ).then((response) => {
      if (response) {
        localStorage.setItem("enrollment_id", response.data.id);
        GetMaker(Endpoints.list_material + course_id).then((res) => {
          if (res) {
            serialize(res.data);
            // console.log("res", res.data);
            // localStorage.setItem("material", JSON.stringify(res.data));
            // localStorage.setItem("material_length", res.data.length);
            // localStorage.setItem("current_material", -1);
            // history.push({
            //   pathname: "/material",
            // });
            localStorage.setItem("lastRoute", "flushed");
            history.push({
              pathname: "/trainer",
            });
          } else {
            document.getElementById("confirm" + key).innerHTML = "Next";
          }
        });
      }
    });

    // history.push({
    //   pathname: "/training",
    // });
  };

  const serialize = (sections) => {
    var materialsAll = [];
    for (let i = 0; i < sections.length; i++) {
      const materials = sections[i].materials;
      for (let j = 0; j < materials.length; j++) {
        const material = materials[j];
        materialsAll.push(material);
      }
    }
    // materialsAll=materialsAll.filter((m) => m.id !== "d5839047-7cf5-44e5-8588-91f4353efe4e");//remove garrel material
    materialsAll=materialsAll.filter((m) => m.id !== "be9c4a11-57e2-48cf-a6db-1a4d76942878");//remove cappeln material
    localStorage.setItem("material", JSON.stringify(materialsAll));
    localStorage.setItem("material_length", materialsAll.length);
    localStorage.setItem("current_material", 0);
  };


  // Function to handle checkbox selection
  // const onAddCourseID = (courseID, key) => {
  //   // Check if the checkbox is selected
  //   const checkbox = document.getElementById("confirm" + key);
  //   if (checkbox.checked) {
  //     // Push the course ID to the selectedCourseIDs array if it's not already present
  //     if (!selectedCourseIDs.includes(courseID)) {
  //       setSelectedCourseIDs([...selectedCourseIDs, courseID]);
  //     }
  //   } else {
  //     // Remove the course ID from the selectedCourseIDs array if it's present
  //     const updatedCourseIDs = selectedCourseIDs.filter((id) => id !== courseID);
  //     setSelectedCourseIDs(updatedCourseIDs);
  //   }
  // };
  const onCheckboxSelected = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    let count = 0;
    const clickedCheckboxes = [];

    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        count++;
        const h4Element = checkbox.parentNode.parentNode.querySelector("h4");
        const name = h4Element.textContent.trim();
        clickedCheckboxes.push(name);
      }
    });

    setSelectedCount(count);
    setClickedCheckboxes(clickedCheckboxes);
    console.log(clickedCheckboxes); // Array containing names of clicked checkboxes
  };


  return (
    <div>
      <div className="bg-mine">
        <h1 class="display-4" id="titlex">
          {Language[language].titlex}
        </h1>

        {/* <hr class="my-4" /> */}
        {isLoading ? (
          <Spinner animation="grow" />
        ) : (
          courses.map((course, key) => {

            // return course.id !== "dd80d2be-841c-42b0-aa8b-46eb83695061" ? ( //for cappeln
            return course.id !== "14c64bd1-952a-49c3-956c-50aba2fc0e9e" ? (
              
              <div
                className="d-flex"
                style={{
                  padding: 20,
                  borderRadius: 10,
                  alignItems: "center",
                  border: "1px solid #dddeee",
                }}
              >
                <h4 className=""> {course.name}</h4>
                
                {/* <label className="checkbox-container">
                  <input
                    type="checkbox"
                    onChange={() => onAddCourseID(course.id, key)}
                    id={"confirm" + key}
                  />
                  <span className="checkmark"></span>
                </label> */}

<button
            type="button"
            className={`btn-round margin-left-auto ${
              selectedCount === 0
                ? "disabled-button"
                : "enabled-button"
            }`}
            onClick={() => onNext(course.id, key, selectedCount)}
            // id="nextButton"
            id={"confirm" + key}
            disabled={selectedCount === 0}
          >
            {Language[language].button_next}
            <i class="fa fa-forward icon-border" aria-hidden="true"></i>
          </button>


                {/* <button
                  type="button"
                  className="btn-round margin-left-auto"
                  onClick={() => onNext(course.id, key, selectedCount)}
                  id={"confirm" + key}
                >
                  {Language[language].button_next}
                  <i class="fa fa-forward icon-border" aria-hidden="true"></i>
                </button> */}
              </div>
            ) : (
              <></>
            );
            // <Card style={{ borderRadius: "20px" }}>

            //     {course.name}

            //     <Card.Body>
            //       <div>
            //         {course.long_description}
            //       </div>
            //       <div
            //         className="d-flex justify-content-end"
            //         style={{ padding: "10px", width: "100%" }}
            //       >
            //         <button
            //           type="button"
            //           className="btn-round margin_left"
            //           onClick={() => onNext(course.id, key)}
            //           id={"confirm" + key}
            //         >
            //           {Language[language].button_next}
            //           <i class="fa fa-forward icon-border" aria-hidden="true"></i>
            //         </button>
            //         {/* <Button
            //           variant="outline-dark"
            //           block
            //           onClick={() => onNext(course.id)}
            //         >
            //           Next
            //         </Button> */}
            //       </div>
            //     </Card.Body>

            // </Card>
          })
        )}


      <div>
            {process.env.REACT_APP_PROJECT_NAME === 'CLOPPENBURG' ? (
              <div>
                        <div
          className="d-flex"
          style={{
            padding: 20,
            borderRadius: 10,
            alignItems: "center",
            border: "1px solid #dddeee",
          }}
        >
          <h4 className=""> PFA1</h4>

          <label className="checkbox-container">
            <input
              type="checkbox"
               onChange={() => onCheckboxSelected()}
            />
            <span className="checkmark"></span>
          </label>

          </div>

          <div
          className="d-flex"
          style={{
            padding: 20,
            borderRadius: 10,
            alignItems: "center",
            border: "1px solid #dddeee",
          }}
        >
          
          <h4 className=""> PFA2</h4>

          <label className="checkbox-container">
            <input
              type="checkbox"
               onChange={() => onCheckboxSelected()}
            />
            <span className="checkmark"></span>
          </label>

          </div>


          <div
          className="d-flex"
          style={{
            padding: 20,
            borderRadius: 10,
            alignItems: "center",
            border: "1px solid #dddeee",
          }}
        >

          <h4 className=""> PFA2a</h4>

          <label className="checkbox-container">
            <input
              type="checkbox"
               onChange={() => onCheckboxSelected()}
            />
            <span className="checkmark"></span>
          </label>

          </div>


          <div
          className="d-flex"
          style={{
            padding: 20,
            borderRadius: 10,
            alignItems: "center",
            border: "1px solid #dddeee",
          }}
        >

          <h4 className=""> PFA3</h4>

          <label className="checkbox-container">
            <input
              type="checkbox"
               onChange={() => onCheckboxSelected()}
            />
            <span className="checkmark"></span>
          </label>

          </div>


          <div
          className="d-flex"
          style={{
            padding: 20,
            borderRadius: 10,
            alignItems: "center",
            border: "1px solid #dddeee",
          }}
        >

          <h4 className=""> PFA4</h4>

          <label className="checkbox-container">
            <input
              type="checkbox"
               onChange={() => onCheckboxSelected()}
            />
            <span className="checkmark"></span>
          </label>

          </div>
                </div>
            ) : (
             <div>

        <div
          className="d-flex"
          style={{
            padding: 20,
            borderRadius: 10,
            alignItems: "center",
            border: "1px solid #dddeee",
          }}
        >
          <h4 className="">Garrel</h4>

          <label className="checkbox-container">
            <input
              type="checkbox"
               onChange={() => onCheckboxSelected()}
            />
            <span className="checkmark"></span>
          </label>
          </div>

          <div
          className="d-flex"
          style={{
            padding: 20,
            borderRadius: 10,
            alignItems: "center",
            border: "1px solid #dddeee",
          }}
        >
          <h4 className="">Cappeln</h4>

          <label className="checkbox-container">
            <input
              type="checkbox"
               onChange={() => onCheckboxSelected()}
            />
            <span className="checkmark"></span>
          </label>
          </div>


          <div
          className="d-flex"
          style={{
            padding: 20,
            borderRadius: 10,
            alignItems: "center",
            border: "1px solid #dddeee",
          }}
        >
          <h4 className="">KÜA</h4>

          <label className="checkbox-container">
            <input
              type="checkbox"
               onChange={() => onCheckboxSelected()}
            />
            <span className="checkmark"></span>
          </label>
          </div>

              </div>
            )}
          </div>
        


        {/* <div
          className="d-flex"
          style={{
            padding: 20,
            borderRadius: 10,
            alignItems: "center",
            border: "1px solid #dddeee",
          }}
        >
          <h4 className=""> PFA1</h4>

          <label className="checkbox-container">
            <input
              type="checkbox"
               onChange={() => onCheckboxSelected()}
            />
            <span className="checkmark"></span>
          </label>

          </div>

          <div
          className="d-flex"
          style={{
            padding: 20,
            borderRadius: 10,
            alignItems: "center",
            border: "1px solid #dddeee",
          }}
        >
          
          <h4 className=""> PFA2</h4>

          <label className="checkbox-container">
            <input
              type="checkbox"
               onChange={() => onCheckboxSelected()}
            />
            <span className="checkmark"></span>
          </label>

          </div>


          <div
          className="d-flex"
          style={{
            padding: 20,
            borderRadius: 10,
            alignItems: "center",
            border: "1px solid #dddeee",
          }}
        >

          <h4 className=""> PFA2a</h4>

          <label className="checkbox-container">
            <input
              type="checkbox"
               onChange={() => onCheckboxSelected()}
            />
            <span className="checkmark"></span>
          </label>

          </div>


          <div
          className="d-flex"
          style={{
            padding: 20,
            borderRadius: 10,
            alignItems: "center",
            border: "1px solid #dddeee",
          }}
        >

          <h4 className=""> PFA3</h4>

          <label className="checkbox-container">
            <input
              type="checkbox"
               onChange={() => onCheckboxSelected()}
            />
            <span className="checkmark"></span>
          </label>

          </div>


          <div
          className="d-flex"
          style={{
            padding: 20,
            borderRadius: 10,
            alignItems: "center",
            border: "1px solid #dddeee",
          }}
        >

          <h4 className=""> PFA4</h4>

          <label className="checkbox-container">
            <input
              type="checkbox"
               onChange={() => onCheckboxSelected()}
            />
            <span className="checkmark"></span>
          </label>

          </div> */}
        </div>

        <br></br>

        {/* <p>Selected Count: {selectedCount}</p> */}

        <div className="button-container">
          {/* <button
            type="button"
            className={`btn-round ${
              selectedCount === 0
                ? "disabled-button"
                : "enabled-button"
            }`}
            onClick={() => onNext(selectedCourseId, "0")}
            id="nextButton"
            disabled={selectedCount === 0}
          >
            {Language[language].button_next}
            <i class="fa fa-forward icon-border" aria-hidden="true"></i>
          </button> */}

        {/* <Accordion>
          <Card>
            <Accordion.Toggle eventKey="0" className="btn btn-outline-dark">
              Course Name
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div>Course Detail</div>
                <div className="row" style={{ padding: "10px" }}>
                  <Button variant="outline-dark" block onClick={onNext}>
                    Next
                  </Button>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <div style={{ marginTop: "20px" }}></div>
        </Accordion> */}
      </div>
    </div>
  );
};

export default Courses;
