export default {
  title_text: {
    en: "Congratulations",
    de: "Herzlichen Glückwunsch!",
    ru: "Поздравляем!",
    po: "Gratulacje!",
    tr: "Tebrikler!",
    pt: "Parabéns!",
  },
  subtitle_text: {
    en: "Thank you for your participation in the briefing. Now you can download your certificate.",
    de: "Vielen Dank für Ihre Teilnahme an der Einweisung. Sie können jetzt Ihr Zertifikat herunterladen.",
    ru: "Благодарим вас за участие в брифинге. Теперь вы можете загрузить свой сертификат.",
    po: "Dziękujemy za udział w spotkaniu informacyjnym. Teraz możesz pobrać swój certyfikat.",
    tr: "Brifte katılımınız için teşekkür ederiz. Şimdi sertifikanızı indirebilirsiniz.",
    pt: "Obrigado por participar do briefing. Agora você pode baixar o seu certificado.",
  },
  click_hint: {
    en: "The certificate was also sent to the administration.",
    de: "Das Zertifikat wurde auch an die Administration geschickt.",
    ru: "Сертификат также был отправлен в администрацию.",
    po: "Certyfikat został również wysłany do administracji.",
    tr: "Sertifika aynı zamanda yönetim tarafına da gönderildi.",
    pt: "O certificado também foi enviado para a administração.",
  },
  download_button: {
    en: "Download",
    de: "Herunterladen",
    ru: "Скачать",
    po: "Pobierz",
    tr: "İndir",
    pt: "Baixar",
  },
  logout_button: {
    en: "Logout",
    de: "Abmeldung",
    ru: "Выйти из системы",
    po: "Wyloguj",
    tr: "Çıkış yap",
    pt: "Sair",
  },
};
