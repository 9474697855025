import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useAuth from "../../utils/customHooks/useAuth";

const BypassObcc = () => {
  useAuth();
  const history = useHistory();
  const [password, setPassword] = useState("");
  useEffect(() => {
    if (password === "qsefthuk") {
      localStorage.setItem("dev", "true");
      history.push({
        pathname: "/courses",
      });
      return;
    }
  }, [password]);
  return (
    <div className="bg-mine">
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span
            className="input-group-text"
            id="basic-addon1"
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              height: 38,
            }}
          >
            <i class="fa-solid fa-key"></i>
          </span>
        </div>
        <input
          type="password"
          className="form-control"
          placeholder="Password"
          aria-describedby="basic-addon1"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default BypassObcc;
