import React, { useEffect, useState } from "react";
import { PDFStreamMaker, PostMaker } from "../../repository/RequestMaker";
import { Spinner } from "react-bootstrap";
import { Endpoints } from "../../repository/Endpoints";
import { useHistory } from "react-router-dom";
import Logout from "../../utils/sharedComponents/Logout";
const TrainingPDF = () => {
  //inits
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [pdfFile, setPdfFile] = useState("");
  const material_id = localStorage.getItem("material_id");

  //logics
  const onNext = (e) => {
    e.preventDefault();
    PostMaker(
      {
        section_material_id: material_id,
        course_enrollment_id: localStorage.getItem("enrollment_id"),
      },
      Endpoints.material_progress
    ).then((response) => {
      const current_material = parseInt(
        localStorage.getItem("current_material")
      );
      localStorage.setItem("current_material", current_material + 1);
      history.push({
        pathname: "/material",
      });
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    PDFStreamMaker(Endpoints.pdf + material_id).then((res) => {
      setPdfFile(res);
      setIsLoading(false);
    });
  }, []);
  return (
    <div className="bg-mine" style={{ height: "80%" }}>
      {isLoading ? (
        <Spinner animation="grow" />
      ) : (
        <>
          <iframe
            id="pdf-js-viewer"
            src={"lib/web/viewer.html?file=" + pdfFile}
            title="webviewer"
            frameborder="0"
            width="100%"
            height="90%"
            style={{ borderRadius: "20px" }}
          ></iframe>
          <div className="margin_top"></div>
          <div className="d-flex justify-content-end">
            <button className="btn-round" onClick={onNext}>
              <i class="fa fa-forward" aria-hidden="true"></i>
            </button>
          </div>
        </>
      )}
      <div style={{ height: "60px" }}></div>
      <Logout />
    </div>
  );
};

export default TrainingPDF;
