export const Translations = (language, LanguageJson) => {
  var statements = LanguageJson[language];
  if (language === "") {
    statements = LanguageJson[localStorage.getItem("language")];
  }
  for (var key in statements) {
    //console.log("key", key);
    var elementExists = document.getElementById(key);
    if (document.body.contains(elementExists)) {
      document.getElementById(key).innerHTML = statements[key];
    }
  }
};
